// This file contains code related to list attribution tracking.

import { trackNavigation } from '.';
import { getCurrency } from '../../util/currency';
import { d3rLocalStorage } from '../../util/storage';
import { dataLayerPush } from '.';

const productListSessionKey = 'productListSession';

export const ensureProductListSessionIdSet = () => {
  if (null === sessionStorage.getItem(productListSessionKey)) {
    const sessionId = '_' + Math.random().toString(36).substring(2, 11);
    sessionStorage.setItem(productListSessionKey, sessionId);
  }
};

export const getProductListSessionId = () => sessionStorage.getItem(productListSessionKey);

export const trackProductListImpressions = ({ list, items, currency }) => {
  items.forEach((item) => {
    let getList = (item.list || list).replace(new RegExp('^' + window.localePathPrefix + '/'), '/');
    item.list = getList.split('?')[0];
  });

  dataLayerPush({
    event: 'productImpression',
    ecommerce: {
      currencyCode: currency ?? getCurrency(),
      impressions: items,
    },
  });

  setListData({ list, items });
};

export const setListData = ({ list, items }) => {
  // Expire any PDP storage data if set
  sessionStorage.removeItem('productListDetail');

  ensureProductListSessionIdSet();

  if (!d3rLocalStorage?.isAvailable()) {
    return;
  }

  const listData = {
    listUrl: window.location.pathname.replace(new RegExp('^' + window.localePathPrefix + '/'), '/'), // The URL path where the customer saw the product.
    canonicalListUrl: window?.mv?.plp?.canonicalListingUrl, // The list name that will be tracked, which isn't necessarily a URL.
    items: items.map(({ id, position }) => ({ id, position })),
  };

  let listingsData = [listData];
  if (d3rLocalStorage.exists('listingsData', 'plp')) {
    listingsData = JSON.parse(d3rLocalStorage.get('listingsData', 'plp'))
    const existingData = listingsData.find((data) => data.listUrl === listData.listUrl && data.canonicalListUrl === listData.canonicalListUrl);
    // added ?. as i was getting .items is undefined for some reason
    if (existingData?.items) {
      for (const newItem of items) {
        const existingItem = existingData.items.find(({ id }) => id === newItem.id);
        if (existingItem) {
          Object.assign(existingItem, newItem);
        } else {
          existingData.items.push(newItem);
        }
      }
    } else {
      listingsData.unshift(listData);
    }

    // Let's try and keep a sensible amount of data in storage and avoid blowing up any memory limits
    listingsData = listingsData.slice(0, 20);
  }

  d3rLocalStorage.set('listingsData', JSON.stringify(listingsData), 'plp');
};

export const getProductElementData = (el, overrides = {}) => {
  const a = el.closest('.js-gtm-element');

  const data = {
    id: a.dataset.gaid,
    name: a.dataset.galabel,
    category: a.dataset.category,
    brand: a.dataset.brand,
    position: parseInt(a.dataset.position, 10),
  };

  if (a.dataset.price) {
    data.price = parseFloat(a.dataset.price);
  }

  if (a.dataset.discount) {
    data.discount = parseFloat(a.dataset.discount);
  }

  return Object.assign(data, overrides);
};

/**
 * Track a click on a product element.
 *
 * @param {PointerEvent} e
 * @param {string} list
 * @returns {void}
 */
export const trackProductElementClick = (e, list, overrides = {}) => {
  e.preventDefault();

  let target = e.target;
  if ('element' in overrides) {
    target = overrides.element;
    overrides = Object.assign({}, overrides);
    delete overrides.element;
  }

  const el = target.closest('.js-gtm-element');

  const product = getProductElementData(el, overrides);

  trackProductClick({
    uri: e.target.closest('a')?.href,
    list,
    product,
  });
};

export const trackProductClick = ({ uri, list, product }) => {
  list = list.replace(new RegExp('^' + window.localePathPrefix + '/'), '/');

  if (d3rLocalStorage?.isAvailable()) {
    const lsObj = {
      sessionId: getProductListSessionId(),
      listId: list,
      canonicalUrl: list,
      position: product.position,
    };
    d3rLocalStorage.set('productListDetail', JSON.stringify(lsObj), 'plp');
  }

  const eventData = {
    event: 'eec.productClick',
    ecommerce: {
      click: {
        actionField: { list },
        products: [product],
      },
    },
  };

  if (uri) {
    trackNavigation(uri, eventData);
  } else {
    dataLayerPush(eventData);
  }
};


